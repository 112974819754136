@import url('https://fonts.googleapis.com/css?family=Prompt:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
@import './variables';
@import '~bootstrap';

$bn-navbar-min-height: 57px;
// $bn-navbar-height: 102px;
$bn-navbar-height: 57px;

// * {outline: 1px dashed crimson;}
html,
body,
#root {
  height: 100%;
}
#root {
  display: flex;
  flex-direction: column;
}
nav {
  height: 56px;
}
.navbar-brand {
  line-height: 1;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  letter-spacing: 2px;
}
.navbar-collapse {
  &.collapsing {
    transition: unset;
  }
  @include media-breakpoint-down(lg) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    z-index: $zindex-modal;
    .dropdown-menu {
      display: block;
    }
  }
}
.dropdown-toggle {
  color: inherit;
}
.no-caret {
  .dropdown-toggle::after {
    content: none;
  }
}
.accordion {
  .card {
    border: none;
    border-bottom: 1px solid $border-color;
    border-radius: 0;
    // &:hover {
    //   background-color: $gray-100;
    // }
  }
  .card-header {
    padding: $spacer 0;
    background: none;
    border: none;
    cursor: pointer;
  }
}
.ratio {
  img {
    width: 100%;
    height: 100%;
    // object-fit: cover;
  }
}
.carousel-indicators {
  [data-bs-target] {
    border-radius: 50%;
  }
}
dl {
  line-height: 1.4;
  dt {
    font-size: .8em;
  }
  dd {
    margin-bottom: $spacer;
    word-break: break-word;
  }
}
.btn-close {
  background-color: $white;
  border-radius: 50%;
}




/* navbar */
.bn-navbar-abs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $zindex-fixed;
  +div>div:first-child {
    padding-top: 56px;
  }
}
.bn-navbar-fixed {
  height: $bn-navbar-height;
  // border-bottom: 1px solid $border-color;
  +div {
    margin-top: ($bn-navbar-height - 1);
  }
  @include media-breakpoint-down(lg) {
    height: $bn-navbar-min-height;
    +div {
      margin-top: ($bn-navbar-min-height - 1);
    }
  }
}
.bn-navbar-menu {
  .navbar-nav {
    width: 100%;
    >li {
      padding: .5rem .25rem;
      border-bottom: 1px solid $border-color;
    }
  }
  .navbar-toggler {
    float: right;
    // padding: .5rem 1.25rem;
    margin: .75rem 0;
  }
  .navbar-toggler,
  a {
    color: $dark !important;
  }
}
.bn-navbar-dropdown {
  min-width: 240px;
  .dropdown-item {
    font-size: .8em;
  }
}

/* modal */
.bn-modal {
  // .close {
  //   width: 48px;
  //   height: 40px;
  //   padding: 0;
  //   background: none;
  //   border: none;
  //   font-size: 3rem;
  //   font-weight: $font-weight-light;
  //   line-height: .5;
  //   opacity: .5;
  //   > span {
  //     display: inline-block;
  //     width: 32px;
  //     line-height: 32px;
  //     // background-color: rgba($white,1);
  //     border-radius: 50%;
  //     color: $gray-500;
  //   }
  //   .sr-only {
  //     display: none;
  //   }
  // }
  // .btn-close {
  //   background-color: $white;
  //   border-radius: 50%;
  // }
  .modal-header {
    padding: 1.5rem 1rem 0 .5rem;
    border-bottom: none;
    z-index: 1;
    position: absolute;
    right: 0;
  }
  &.close-start {
    .modal-header {
      @include media-breakpoint-down(lg) {
        right: unset;
      }
    }
  }
  .modal-body {
    padding: 3rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    @include media-breakpoint-down(sm) {
      padding: 3rem 1rem;
    }
  }
  .modal-dialog {
    @include media-breakpoint-down(sm) {
      min-height: 100%;
      margin: 0 auto;
      padding-top: 3rem;
      align-items: flex-end;
    }
  }
  &.fade>.modal-dialog {
    transform: translate(0, 50px);
  }
  &.show>.modal-dialog {
    transform: none;
  }
  .modal-content {
    overflow: hidden;
    @include media-breakpoint-down(sm) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &-cover {
    width: auto;
    margin: -3rem -3rem 0;
    @include media-breakpoint-down(sm) {
      margin: -3rem -1rem 0;
    }
    // &:after{
    //   content: "";
    //   display: block;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background: linear-gradient(135deg, rgba($dark,.5), transparent);
    // }
  }
}
.bn-modal-ads {
  .modal-header {
    position: absolute;
    z-index: 1;
    padding-top: 1.5rem;
    border-bottom: none;
  }
  .modal-body {
    padding: 0;
    border-radius: $border-radius;
    overflow: hidden;
  }
}

/* card */
.bn-card-md-resp {
  .card-title a {
    color: inherit;
  }
  @include media-breakpoint-down(lg) {
    flex-direction: row;
    border: none;
    border-bottom: 1px solid $border-color;
    border-radius: 0;
    padding: 1rem 0;
    >div:first-child {
      width: 32vw;
      max-width: 150px;
      margin-right: 1rem;
    }
    .ratio {
      border-radius: 8px;
    }
    .card-body {
      padding: 0;
      flex-shrink: 10;
    }
  }
  @include media-breakpoint-up(lg) {
    border-radius: 8px;
    .btn-sm {
      font-size: .75rem;
    }
  }
}
.bn-card-banner {
  .bn-card-couponinfo{
    margin-top: 2px
  }
  @include media-breakpoint-down(lg) {
    .bn-card-banner-text {
      flex-direction: column !important;
      align-items: stretch !important;
      padding: 0 !important;
      .btn {
        width: 100%;
      }
    }
    .bn-card-banner-earn {
      display: inline !important;
      margin-left: .5rem;
    }
    .bn-card-banner-btn {
      margin-left: unset !important;
      padding-top: .5rem;
    }
    .bn-card-couponinfo {
      display: none;
      
    }
  }
}
.bn-card-sold {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($white,.75);
  display: flex;
  justify-content: center;
  align-items: center; 
  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background-color: rgba($dark,.75);
    color: $white;
  }
}

/* section */
.bn-section {
  h2 {
    @include media-breakpoint-down(lg) {
      font-size: calc(.8rem + 1.38vw);
    }
  }
  small {
    @include media-breakpoint-down(lg) {
      font-size: calc(.5rem + 1.38vw);
      display: block;
    }
  }
  .bn-logo-hr {
    @include media-breakpoint-down(lg) {
      height: calc(12px + 1.38vw);
    }
  }
  .bn-more {
    padding-left: 1rem;
    @include media-breakpoint-down(lg) {
      font-size: calc(.65rem + 1.38vw);
    }
  }
}
.bn-section-slide {
  @include media-breakpoint-down(lg) {
    overflow-x: auto;
    padding-bottom: 1rem; /* Safari */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar for Chrome, Safari and Opera */
    }
  }
  .row {
    @include media-breakpoint-down(lg) {
      width: 90%;
      flex-wrap: nowrap;
    }
  }
}

.bn-section-s1 {
  // background-color: #ECFAE3;
  position: relative;
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    background-color: #F6FAE3;
    @include media-breakpoint-down(lg) {
      height: 50vw;
      min-height: 240px;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: -100px;
    right: -15%;
    width: 200%;
    height: 500px;
    background-color: #F6FAE3;
    border-radius: 50%;
    transform: rotate(6deg);
    // outline: 1px dashed red;
    z-index: 0;
    @include media-breakpoint-down(lg) {
      top: calc(-400px + 45vw);
      right: -40%;
    }
  }
  .container {
    position: relative;
    z-index: 1;
  }
}
.bn-section-s2 {
  position: relative;
  background-color: #ECFAE3;
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    top: -100px;
    right: -20%;
    width: 150%;
    height: 200px;
    background-color: white;
    transform: rotate(3deg);
  // outline: 1px dashed red;
  }
  .container {
    position: relative;
  }
}
.bn-section-q {
  display: block;
  padding: 0 $spacer*2;
  text-align: center;
  &:before,
  &:after {
    display: block;
    font-size: 2em;
    font-weight: bold;
    line-height: 1;
    padding-top: 0.5em;
  }
}


/* carousel */
.bn-carousel {
  .ratio > * {
    @include media-breakpoint-up(lg) {
      padding-left: 30%;
    }
  }
  .carousel-item {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      @include media-breakpoint-down(lg) {
        background: rgba($dark, .5);
      }
      @include media-breakpoint-up(lg) {
        background: linear-gradient(
          90deg, 
          rgba($dark, 1) 31%, 
          transparent
        );
      }
    }
  }
  .carousel-caption {
    z-index: 1;
    text-align: left;
    @include media-breakpoint-down(lg) {
      top: 0;
      right: 0; // hack
      bottom: 0; // hack
      left: 0; // hack
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      display: flex;
      align-items: flex-end;
    }
    @include media-breakpoint-up(lg) {
      top: 10%;
      // right: unset; // hack
      bottom: unset; // hack
      left: 10%; // hack
    }
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: 10%;
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
  &.hide-control {
    .carousel-control-prev,
    .carousel-control-next,
    .carousel-indicators {
      display: none;
    }
  }
  .stretched-link {
    @include media-breakpoint-up(lg) {
      &:after {
        content: none;
      }
    }
  }
}
.bn-carousel-banner {
  // .ratio img {
  //   object-fit: contain;
  //   object-position: top;
  // }
  .carousel-inner {
    border-radius: $border-radius;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: 10%;
    &:hover {
      background-color: rgba($dark,.2);
    }
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
  // .carouseltota-indicators {
  //   @include media-breakpoint-down(lg) {
  //     margin-bottom: -2rem;
  //     [data-bs-target] {
  //       background-color: rgba($dark,.5);
  //     }
  //   }
  // }
  &.hide-control {
    .carousel-control-prev,
    .carousel-control-next,
    .carousel-indicators {
      display: none;
    }
  }
}




.bn-btn-hover {
  transition: all ease .2s;
  &:focus,
  &:hover {
    background-color: rgba($dark,.025);
  }
  &:active {
    background-color: rgba($dark,.1);
  }
}

/* button-tabs */
.bn-tabs {
  &-btn {
    border-radius: $border-radius-pill;
  }
  @include media-breakpoint-down(lg) {
    position: fixed;
    top: 54px;
    left: 0;
    width: 100%;
    background-color: $white;
    border-bottom: 1px solid $border-color;
    z-index: $zindex-fixed;
    +div {
      margin-top: 2rem;
    }
    &-btn {
      width: 100%;
      border-radius: 0;
      border-width: 0 0 4px 0;
      &.btn-primary {
        background: none;
        color: $primary;
        &:active {
          box-shadow: none;
        }
      }
      &.btn-outline-primary {
        border-color: transparent;
        opacity: .5;
        color: inherit;
        &:hover {
          background: none;
          color: inherit;
        }
        &:active {
          box-shadow: none;
        }
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
}

/* button-addon */
.bn-btn-group-addon-left {
  border: none;
  background: none;
  padding-left: 1.5rem;
  position: absolute !important;
  left: 0;
  height: 100%;
  z-index: 9;
  +* {
    padding-left: 56px;
    border-radius: 24px !important;
  }
}
.bn-btn-copy {
  position: absolute !important;
  bottom: 0;
  right: 0;
}

/* button-login */
.bn-btn-login {
  width: 100%;
  height: 64px;
  border-radius: $border-radius-pill;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 90%;
}
.bn-btn-fb {
  $color: #1778F2;
  @include button-variant(
    $color, 
    darken($color, 2%), 
    lighten($color, 100%), 
    darken($color, 1%), 
    darken($color, 2%), 
    lighten($color, 80%)
  );
}






/* profile */
// .bn-profile {
//   position: relative;
//   height: 120px;
//   background-color: rgba($white,.9);
//   background-image: linear-gradient(
//     181deg, 
//     transparent,
//     rgba($info, 0.1)
//   );
//   &_image {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-repeat: repeat-x;
//     background-position: center -560px;
//     opacity: .1;
//   }
//   +div {
//     @include media-breakpoint-up(lg) {
//       margin-top: $bn-navbar-height;
//     }
//   }
// }
.bn-profile {
  position: relative;
  height: 120px;
  &_image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: repeat-x;
    opacity: .3;
  }
  +div {
    @include media-breakpoint-up(lg) {
      margin-top: $bn-navbar-height;
    }
  }
  &.style-1 {
    background-color: $white;
    background-image: linear-gradient(
      181deg, 
      transparent 50%,
      rgba($info, .3)
    );
    .bn-profile_image {
      background-position: 0 -200px;
      background-size: 990px;
      background-attachment: fixed;
    }
  }
  &.style-2 {
    background-color: rgba($primary,.1);
    .bn-profile_image {
      background-position: 0 -100px;
      background-size: cover;
    }
  }
}
.bn-avatar {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 1px solid $border-color;
  background-color: $light;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block; // hack space offset
  }
}


.bn-filter {
  // position: sticky;
  // top: calc(#{$bn-navbar-height} - .5rem);
  // z-index: $zindex-sticky;
  // @include media-breakpoint-down(lg) {
  //   top: $bn-navbar-height;
  //   max-width: none;
  //   padding: 0;
  //   .alert {
  //     border-radius: 0;
  //   }
  // }
  .dropdown {
    .dropdown-toggle {
      @extend .bn-btn-hover;
      height: 56px;
      display: flex;
      align-items: center;
      border-radius: $border-radius;
      border: 1px solid $border-color;
      color: $body-color;
      label {
        cursor: pointer;
      }
      @include media-breakpoint-down(sm) {
        height: 48px;
        padding: 0 .5rem;
        font-size: 90%;
        .bn-filter_i1 {
          display: none;
        }
      }
    }
    &.active {
      .dropdown-toggle {
        border-color: $primary;
        color: $body-color;
      }
    }
    .dropdown-menu {
      min-width: 200px;
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
      @include media-breakpoint-down(sm) {
        width: calc(100vw - 2rem);
      }
    }
    &.split {
      .dropdown-menu {
        @include media-breakpoint-up(lg) {
          width: 320px;
        }
      }
    }
    &.show {
      .bn-icon {
        transform: rotate(180deg);
      }
    }
  }
}


.bn-stamp {
  &_circle {
    border-radius: 50%;
    border: 3px solid $light;
  }
  &_stamped {
    position: absolute;
    top: 0;
    left: 0;
    opacity: .8;
  }
  &_reward {
    position: absolute;
    height: auto !important;
    top: 10%;
    left: -2%;
    transform: scale(1.6);
    & + * {
      position: relative;
      top: 2rem;
      white-space: nowrap;
      padding: 3px;
      background-color: $white;
      @include media-breakpoint-up(lg) {
        top: 2.5rem;
      }
    }
  }
  &_pop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($white,.8);
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      position: relative;
      width: 400px;
      margin: 0 $spacer;
      box-shadow: 0 0 8rem 4rem $white;
      img {
        position: absolute;
        top: -100px;
        left: 0;
        width: 100%;
      }
    }
    &.fade {
      transition: all ease .3s;
      opacity: 0;
      z-index: -1;
      > div {
        transition: all ease .3s;
        opacity: 0;
        top: 50px;
        transform: scale(1.2);
      }
    }
    &.show {
      opacity: 1;
      z-index: 1;
      > div {
        transition-delay: 1s;
        opacity: 1;
        top: 0;
        transform: scale(1);
      }
    }
  }
}




.bn-blockquote {
  position: relative;
  margin-bottom: $spacer;
  // padding-bottom: $spacer/4;
  p {
    margin: 0;
    padding: 0.75rem 2rem 1rem 1.5rem;
    font-size: 65%;
  }
  p::before,
  p::after {
    font-family: cursive;
    font-size: 4rem;
    position: absolute;
    opacity: 0.25;
  }
  p::before {
    content: "“";
    top: -1.5rem;
    left: -.75rem;
  }
  // p::after {
  //   content: "”";
  //   bottom: -3rem;
  //   right: -1rem;
  // }
}

.bn-logo {
  text-transform: uppercase;
  letter-spacing: 2px;
}
.bn-logo-hr {
  height: 32px;
}
.bn-app-menu {
  position: relative;
  padding: $spacer/2 0;
  text-align: center;
  a {
    color: inherit;
  }
}
.bn-date-fm-1 {
  display: flex;
  align-items: end;
  line-height: 1;
  font-size: .8em;
  @include media-breakpoint-down(md) {
    font-size: 65%;
  }
  >span {
    margin-right: 4px;
    flex-shrink: 0;
    font-size: 2em;
    width: 40px;
    text-align: right;
    color: $secondary;
  }
  >small {
    flex-grow: 1;
    line-height: 1.1;
    opacity: .75;
  }
}
.bn-fade {
  opacity: .5;
}
.bn-small {
  font-size: 65%;
}
.bn-med {
  font-size: 75%;
}
.bn-container-full {
  $nm: calc(#{$spacer} * -1);
  @include media-breakpoint-down(lg) {
    margin-left: $nm;
    margin-right: $nm;
  }
}
.bn-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($black, .5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $zindex-popover;
  >* {
    width: 4rem;
    height: 4rem;
  }
}
.bn-show {
  height: 0;
  opacity: 0;
  transition: opacity ease .2s;
  &.showing {
    height: auto;
    opacity: 1;
  }
}

.bn-footer {
  width: 100%;
  vertical-align: middle;
  font-size: 70%;
}

.bn-gradient-1 {
  background-color: rgba($secondary, 0.05);
  background-image: linear-gradient(135deg, transparent 0%, rgba($primary, 0.1) 100%);
}
.bn-gradient-2 {
  background-color: rgba($secondary, 0.05);
  background-image: linear-gradient(135deg, transparent 0%, rgba($info, 0.1) 100%);
}
.bn-gradient-3 {
  background-color: rgba($secondary, 0.05);
  background-image: linear-gradient(135deg, transparent 0%, rgba($danger, 0.1) 100%);
}
.bn-btn-gradient {
  background-image: linear-gradient(135deg, transparent 0%, rgba($secondary, 0.5) 100%);
}

*:focus {
  outline: none !important;
}
